import React from 'react'
// components
import Navbar from './Navbar'
import Footer from './Footer'
// css
import './layout.css'

const Layout = ({ children }) => {
  return (
    <main>
      <Navbar />
      {children}
      <Footer />
    </main>
  )
}

export default Layout
