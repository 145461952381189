import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const getMetaData = graphql`
	query {
		site {
			siteMetadata {
				siteTitle: title
				siteDesc: description
				author: author
			}
		}
	}
`;

const Seo = ({ title }) => {
	const { site } = useStaticQuery(getMetaData);
	const { siteTitle, siteDesc, author } = site.siteMetadata;

	return (
		<Helmet htmlAttributes={{ lang: 'nl' }} title={`${title} | ${siteTitle}`}>
			<meta name='description' content={siteDesc} />
            <meta name="author" content={author}></meta>
		</Helmet>
	);
};

export default Seo;
