import React from 'react'
import styled from 'styled-components'

const Title = ({ title, subtitle, className }) => {
  return (
    <div className={className}>
      <h4>
        <span className="title">{title}</span>
        <span>{subtitle}</span>
      </h4>
    </div>
  )
}

export default styled(Title)`
  font-size: 2rem;
  margin: 0 0 2rem 0;
  text-transform: uppercase;

  h4 {
    color: var(--primaryColor);
    letter-spacing: 4px;
    text-align: center;
  }

  .title {
    color: var(--mainBlack);
  }

  span {
    display: block;
    margin: 0 0.35rem;
  }

  @media (min-width: 576px) {
    span {
      display: inline-block;
      font-size: 2.3rem;
    }
  }
`
