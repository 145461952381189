import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styles from '../css/footer.module.css'
import links from '../constants/links'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.links}>
        {links.map((link, index) => {
          return (
            <AniLink fade key={index} to={link.path}>
              {link.name}
            </AniLink>
          )
        })}
        <AniLink fade to="/financieel">Financieel</AniLink>
        <AniLink fade to="/privacy">Privacyverklaring</AniLink>
      </div>
      <div className={styles.copyright}>
        copyright &copy; stichting met uitgestrekte hand{' '}
        {new Date().getFullYear()} alle rechten voorbehouden.
      </div>
    </footer>
  )
}

export default Footer
