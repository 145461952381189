import React, { useState } from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styles from '../css/navbar.module.css'
import { FaAlignRight, FaTimes } from 'react-icons/fa'
import links from '../constants/links'
import logo from '../images/logo.svg'

const Navbar = () => {
  // setup state
  const [isOpen, setNav] = useState(false)
  // setup toggle function
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <nav className={styles.navbar}>
      <div className={styles.navCenter}>
        <div className={styles.navHeader}>
          <img className={styles.logo} src={logo} alt="Logo" />
          <button type="button" className={styles.logoBtn} onClick={toggleNav}>
            {isOpen ? (
              <FaTimes className={styles.logoIcon} />
            ) : (
              <FaAlignRight className={styles.logoIcon} />
            )}
          </button>
        </div>
        <ul
          className={
            isOpen
              ? `${styles.navLinks} ${styles.showNav}`
              : `${styles.navLinks}`
          }
        >
          {links.map((link, index) => {
            return (
              <li key={index}>
                <AniLink fade to={link.path}>{link.name}</AniLink>
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
