export default [
  {
    path: '/',
    name: 'home',
  },
  {
    path: '/projecten',
    name: 'projecten',
  },
  {
    path: '/nieuws',
    name: 'nieuws',
  },
  {
      path: '/plannen',
      name: 'plannen'
  },
  {
    path: '/contact',
    name: 'contact',
  },
]
